import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import Projects from "../components/projets";

export const IndexPageTemplate = ({
  title,
  date,
  image,
  description,
  slider,
  client,
  services,
  video
}) => (
  <Projects
    title={title}
    date={date}
    description={description}
    client={client}
    image={image}
    slider={slider}
    video={video}
    services={services}
  />
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  slider: PropTypes.array,
  client: PropTypes.string
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        date={frontmatter.date}
        description={frontmatter.description}
        client={frontmatter.client}
        image={frontmatter.image}
        slider={frontmatter.slider}
        video = {frontmatter.video}
        services={frontmatter.services}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query  ProjectPageQuery($id: String!)  {
    markdownRemark(id: { eq: $id })  {
      excerpt
      frontmatter {
        title
        date
        client
        services
        description
        video
        slider {
          slide {
            childImageSharp {
              fluid(maxWidth: 400,maxHeight:300,quality:100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
