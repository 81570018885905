import React from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import Img from "gatsby-image";
import { Container, UnderLine, Button } from "../global-style";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import useServicesData from "../SiteServices";
import Helmet from 'react-helmet';

import {
  CarouselProvider,
  Slider as PureSlider,
  Slide as PureSlide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Link } from "gatsby";

const HeroProject = styled.div`
  padding: 4rem 1rem;
  color: white;
  background: var(--tbwa-overlay);
  & > * {
    max-width: 40rem;
  }
  a{
    color: white;
  }
  a:hover{
    color: var(--tbwa-yellow);
  }
  blockquote p {
    margin: 0.1rem 0;
    font-size: 1rem;
  }
`;
const IframeContainer = styled.div`
  height: 50vh;
  width: 100%;
  position: relative;
  margin: 1rem 0;
  iframe {
    position: absolute;
    border: unset;
    box-shadow: 1px 1px 5px var(--tbwa-overlay);
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;
const Index = ({
  title,
  description,
  image,
  slider,
  date,
  client,
  services,
  video
}) => {
  const myServices = useServicesData();
  const findServiceSlug = title => {
    const result = myServices.find(service => {
      return service.node.frontmatter.title === title;
    });
    return result.node.fields.slug;
  };
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} data-react-helmet="true" />
        <meta property="og:image" content={image.childImageSharp.fluid.src} data-react-helmet="true" />
        <meta property="og:description" content={description} data-react-helmet="true" />
      </Helmet>
      <BackgroundImage fluid={image.childImageSharp.fluid} backgroundColor="#000000">
        <HeroProject>
          <h1>{title}</h1>
          <blockquote>
            <p>Client : {client}</p>
            <p>Date : {format(new Date(date), "MMMM yyyy", { locale: fr })}</p>
            {services?.length > 0 && (
              <p>
                Projet :
                {services.map((service) => (
                  <Link to={findServiceSlug(service)}>\ {service} </Link>
                ))}
              </p>
            )}
          </blockquote>
          <p>{description}</p>
        </HeroProject>
      </BackgroundImage>
      <Container>
        <h1>Portfolio</h1>
        <UnderLine />
      </Container>

      <Slider slides={slider} />
      <Container>
        {video && (
          <IframeContainer>
            <iframe width="100%" height="100%" src={video} />
          </IframeContainer>
        )}
        <Button>
          <Link to="/projects">Voir d'autres projets</Link>{" "}
        </Button>
      </Container>
    </div>
  );
};
export default Index;

const SliderContainer = styled.div`
  padding-right: 0.5rem;
`;
const Slider = props => {
  const { slides } = props;
  return (
    <>
      <CarouselProvider
        naturalSlideWidth={400}
        naturalSlideHeight={300}
        totalSlides={slides.length}
        visibleSlides={2}
        style={{ marginTop: "2rem" }}
      >
        <ButtonBack>Back</ButtonBack>
        <ButtonNext>Next</ButtonNext>
        <PureSlider>
          {slides.map((mySlide, index) => {
            return (
              <PureSlide index={index}>
                <SliderContainer>
                  <Img fluid={mySlide.slide.childImageSharp.fluid} />
                </SliderContainer>
              </PureSlide>
            );
          })}
        </PureSlider>
      </CarouselProvider>
    </>
  );
};
